
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'ImplementerDescription',
})
export default class ImplementerDescription extends Vue {
  @Prop({ default: '' })
  readonly content: string
}
